import axios from "axios";
import { CommunityModel } from "../domain/CcommunityModel";
import { httpApi } from './http.api';




export const getCommunity = (): Promise<undefined> =>
  httpApi.get<undefined>('community').then(({ data }) => data);





export const getSideMenu = async (): Promise<any> => {
    try {
      const response = await axios.get<CommunityModel>(process.env.REACT_APP_BASE_URL+'/servicecatagory');//  
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      throw new Error(e);
    }
};


export const getSideMenuByCommunityCode = async (communityCode:string): Promise<any> => {
  try {
    const response = await axios.get<CommunityModel>(process.env.REACT_APP_BASE_URL+'/servicecatagory/menuAll/'+communityCode);//  
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw new Error(e);
  }
};

  