import React from 'react';
import * as S from './References.styles';
import { FacebookOutlined, GithubOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper   >
     
      

      <S.BottomText>        
        
      </S.BottomText>

      <S.BottomText>
      <a style={{ color:'#FFF', letterSpacing: '2px', fontSize:'12px', fontFamily: 'Poppins, sans-serif', fontWeight: 700}}  href="/privacy/privacyAgree" target="_blank" rel="noreferrer">
          Privacy & Agreement
        </a> {' | '} <span>
        {/*   <a style={{ color:'#FFF', letterSpacing: '2px',fontSize:'12px', fontFamily: 'Poppins, sans-serif', fontWeight: 700}}  href="/" target="_blank" rel="noreferrer">
          FlexDialog Software Services Inc{' '}in 2024 &copy;.
        </a> */}
        </span> 
      </S.BottomText>
 
      

       <S.Icons>

     
       
       {/*  <a href="https://twitter.com/altence_team" target="_blank" rel="noreferrer">
          <TwitterOutlined />
        </a>
        <a href="https://www.facebook.com/groups/altence" target="_blank" rel="noreferrer">
          <FacebookOutlined />
        </a>
        <a href="https://linkedin.com/company/altence" target="_blank" rel="noreferrer">
          <LinkedinOutlined />
        </a> */}
      </S.Icons> 
    </S.ReferencesWrapper>
  );
};
