import React from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';
import { BaseRow } from './../../../components/common/BaseRow/BaseRow';
import { BaseCol } from './../../../components/common/BaseCol/BaseCol';
import { BaseSpace } from '../../common/BaseSpace/BaseSpace';
import EditIcon from '@mui/icons-material/Edit';
import { readCommunityCode } from './../../../services/localStorage.service';
import logo from './../../../assets/servicegatelo.png';
import { Link } from 'react-router-dom';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow   justify="space-between">
        <BaseCol>
        <Link to="/">
             <img style={{marginBottom:'20px'}} src={logo} width={220} />
        </Link>
        {/*   <S.GHButton /> */}
        </BaseCol>

       
        <BaseCol>
       {/*  <HeaderSearch /> */}
        {/*   <S.GHButton /> */}
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
   
      
           
      {/* <BaseCol lg={10} xxl={8}>
       
       <Link to="/">
             <img src={logo} width={200} />
            </Link>   
      </BaseCol> */}

<BaseCol lg={10} xxl={8}>
        <Link to="/">
             <img style={{marginBottom:'50px', borderColor:'#FFF'}} src={logo} width={250} />
            </Link>
       
      </BaseCol>
 {/*   <S.GHButton /> */}
    {/*  <BaseCol >
       <HeaderSearch /> 
        {/* <S.GHButton /> 
      </BaseCol>*/}
    </>
  );

  const communityCode = readCommunityCode();

  return (
    <BaseRow   justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn style={{marginBottom:'15px'}} xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow  align="middle" justify="end" gutter={[5, 5]}>
          <BaseCol>
            <BaseRow gutter={[{ xxl: 5 }, { xxl: 5 }]}>

            <BaseCol>
           
          </BaseCol>

              <BaseCol> 
               
                <BaseSpace align="center">
                  {/*  <HeaderFullscreen /> */}
                </BaseSpace>
              </BaseCol>

              <BaseCol>
                <BaseSpace align="center">
                   <div>&nbsp;</div>
                </BaseSpace>                
              </BaseCol>

              <BaseCol>
              <BaseSpace align="center" >
            {/*   <NotificationsDropdown /> */}
              </BaseSpace>                
              </BaseCol>

              <BaseCol>
                <BaseSpace align="center" >
                   <div>&nbsp;</div>
                </BaseSpace>                
              </BaseCol>

              <BaseCol>
              <BaseSpace align="center" >
               {/*  <SettingsDropdown /> */}
                </BaseSpace>
              </BaseCol>

              <BaseCol>
                <BaseSpace align="center">
            {/*   {communityCode ? (<div>{'Edit Community'}<EditIcon /></div>):<></> }   */}
                </BaseSpace>                
              </BaseCol>


             

            </BaseRow>
          </BaseCol>

          <BaseCol>
            <ProfileDropdown />
          </BaseCol>
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
