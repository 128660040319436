import React from 'react';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { HeaderSearch } from '../components/HeaderSearch/HeaderSearch';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import * as S from '../Header.styles';
import { BaseRow } from './../../../components/common/BaseRow/BaseRow';
import { BaseCol } from './../../../components/common/BaseCol/BaseCol';
import * as Z from './../../layouts/main/sider/MainSider/MainSider.styles';
import logo from './../../../assets/servicegatelo.png';
import logoDark from './../../../assets/logo-dark.png';
import { useAppSelector } from './../../../hooks/reduxHooks';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import { readCommunityCode } from './../../../services/localStorage.service';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
interface MobileHeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({ toggleSider, isSiderOpened }) => {

  const theme = useAppSelector((state: any) => state.theme.theme);
  const img = theme === 'dark' ? logoDark : logo;
  const communityCode = readCommunityCode();

  return (
    <div>
    <BaseRow justify="space-between" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }} align="middle">
    
      <BaseCol>
        <ProfileDropdown />
      </BaseCol>

      <BaseCol>
                
             <Link to="/">
             <img src={logo} width={180} /> 
            </Link>
      </BaseCol>

      <BaseCol>
        <BaseRow align="top">

        {/*   <Z.SiderLogoLink to="/">
            <p style={{ fontSize: '40px' }}>{'{'}<span style={{ fontSize: '20px', color: 'red' }}>Service</span><span style={{ fontSize: '20px', color: 'blue' }}>{' To '}</span><span style={{ fontSize: '20px', color: 'green' }}>{' Get '}</span>{'}'}</p>
          </Z.SiderLogoLink> */}
          <BaseCol>
                
            
          </BaseCol>

          <BaseCol>
      {/* <HeaderSearch /> */}
      </BaseCol>

         {/*  <BaseCol>
             {communityCode?(<div>{'Edit Community'}<EditIcon /></div>):<></>}            
          </BaseCol> */}

  

          {/*  <BaseCol>
            <NotificationsDropdown />
          </BaseCol>

          <BaseCol>
            <HeaderSearch />
          </BaseCol>

          <BaseCol>
            <SettingsDropdown />
          </BaseCol> */}
        </BaseRow>
      </BaseCol>

      <S.BurgerCol> 
        <div onClick={toggleSider}>
        <p style={{marginLeft:'5px', color:'#FFF'}}><S.MobileBurger  onClick={toggleSider}  isCross={isSiderOpened} /></p>
        <p style={{marginTop:'-5px', color:'#FFF'}}>Menu</p>
        </div>
      </S.BurgerCol>
      
    </BaseRow>
   
    </div>
  );
};
