import axios from "axios";
import { CommunityModel } from "../domain/CcommunityModel";
import { httpApi } from './http.api';



export const getServiceProviderData = (inpReq: any): Promise<undefined> =>
  httpApi.post<undefined>('serviceprovider/serviceTypeByCommunity', { ...inpReq }).then(({ data }) => data);

export const getServiceTypeIds= async (providerIds:any): Promise<any> => {
    try {
      const response = await axios.get<any>(process.env.REACT_APP_BASE_URL+'/serviceprovider/serviceTypeIds/'+providerIds);
    //  const response = await axios.get<any>('http://localhost:5000/api/v1/serviceprovider/serviceTypeIds/'+providerIds);
      return response.data || [];
    } catch (e: any) {
      throw new Error(e);
    }
};

export const getDiscountList= async (providerIds:any): Promise<any> => {
  try {
    const response = await axios.get<any>(process.env.REACT_APP_BASE_URL+'/discounts/discountList/'+providerIds);
   // const response = await axios.get<any>('http://localhost:5000/api/v1/discounts/discountList/'+providerIds);
    return response.data || [];
  } catch (e: any) {
    throw new Error(e);
  }
};




export const getServiceProviderData1 = async (serviceType:any, communityCode: string): Promise<any> => {
 
  if(!communityCode)
      communityCode = 'auburn-mckinney-tx';

    try {
     const response = await axios.get<any>('https://service-to-get-api-4f868ffdd2cb.herokuapp.com/api/v1/serviceprovider/serviceTypeByCommunity/'+serviceType+'/'+communityCode);
  //   const response = await axios.get<any>('http://localhost:5000/api/v1/serviceprovider/serviceTypeByCommunity/'+serviceType+'/'+communityCode);
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      throw new Error(e);
    }
};


export const createServiceProviderData = async (request:any): Promise<any> => {
 
  try {
   const response = await axios.post<any>('https://service-to-get-api-4f868ffdd2cb.herokuapp.com/api/v1/serviceprovider', { ...request });
 //  const response = await axios.post<any>('http://localhost:5000/api/v1/serviceprovider', { ...request });
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};
