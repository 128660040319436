import { WithChildrenProps } from './../../../../types/generalTypes';
import React from 'react';
import * as S from './MainHeader.styles';

interface MainHeaderProps extends WithChildrenProps {
  isTwoColumnsLayout: boolean;
}

export const MainHeader: React.FC<MainHeaderProps> = ({ isTwoColumnsLayout, children }) => {
  return <S.Header style={{background:'#5a32f1', height:'110px'}} $isTwoColumnsLayoutHeader={isTwoColumnsLayout}>{children}</S.Header>;
};
